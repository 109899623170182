import { Controller } from 'stimulus';

export default class extends Controller {
  copy(event) {
    event.preventDefault();
    const link = this.element.href;
    navigator.clipboard.writeText(link);
    this.element.innerText = "✓";
  }

  copyFromValue() {
    const value = this.element.dataset.value;
    navigator.clipboard.writeText(value);
    const initialHTML = this.element.innerHTML;
    this.element.classList.add("text-danger")
    this.element.classList.remove("text-black")
    this.element.innerHTML = "✓";
    setTimeout(() => {
    this.element.classList.remove("text-danger")
      this.element.innerHTML = initialHTML;
    }, 3000);
  }
}
