// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "bootstrap"
import Tooltip from "bootstrap/js/dist/tooltip";

const initTooltip = () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
};

document.addEventListener('turbo:load', (event) => {
  initTooltip();
});

document.addEventListener('turbo:frame-load', (event) => {
  initTooltip();
});
